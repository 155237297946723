import React, { useState } from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// const ProfileContainer = styled.button`
//   padding: 0 12px 20px;
//   display: flex;
//   alignitems: center;
//   background: transparent;
//   border: 0;
//   width: 100%;
// `;

// const Info = styled.div`
//   align-items: flex-start;
//   display: flex;
//   flex-direction: column;
//   margin-right: auto;
//   min-width: 0;
//   text-align: left;

//   & > div {
//     flex: 1;
//   }

//   .name {
//     color: #fff;
//     font-weight: 500;
//   }

//   .company {
//     color: #878b9f;
//   }
// `;

// const Avatar = styled.div`
//   height: 34px;
//   width: 34px;

//   img {
//     border-radius: 50%;
//   }
// `;

function Profile() {
  const { user } = useSelector((state) => state.user);
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
  };

  return (
    <div className="Profile">
      <button className="Profile_opener">
        <div className="Profile_info">
          <div className="Profile_name">{user?.name}</div>
          <div className="Profile_organization">{user?.company}</div>
        </div>
        <div className="Profile_avatar">
          <span className="avatar_container avatar_size">
            <div className="avatar user_avatar avatar_size">
              <div className="avatar_border"></div>
              <div className="avatar_initials"></div>
              {!imgError ? (
                <img
                  className="avatar_image"
                  src={user?.icon}
                  alt={user?.name}
                  width="32"
                  height="32"
                  onError={handleImageError}
                />
              ) : (
                <div
                  style={{
                    fontSize: "22px",
                    marginTop: "25%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  {user?.name?.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
          </span>
          {/* <Link to="/settings/account">
            <img src={user?.icon} alt={user?.name} />
          </Link> */}
        </div>
      </button>
    </div>
  );
}

export default Profile;
