import { useDispatch, useSelector } from "react-redux";
import Payment from "./Payment";
import styled from "styled-components";
import { setCurrentPlan } from "../../features";
import { Load, Loader } from "./components/SkeletonLoader";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const ServicesPageIndex = ({
  uId,
  planId,
  promoData,
  isLoading,
  isPlanAvailable,
  allPlans,
  totalAmmount,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [planCardList, setPlanCardList] = useState([]);

  const [isDisable, setisDisable] = useState(false);
  const [trialStatus, setTrialStatus] = useState(false);

  const queryParams = {
    deals: searchParams.get("deals"),
    plan_id: searchParams.get("plan_id"),
  };

  const plan_Id = {
    s_1: "s_1",
    p_1: "p_1",
    pr_1: "pr_1",
    ul_1: "ul_1",
  };
  const couponCode = {
    hbvjikg: "hbvjikg",
  };

  const { currentPlan, planCards } = useSelector((store) => store.auth);

  useEffect(() => {
    if (searchParams.get("offers") === "off_50") {
      setTrialStatus(true);
    }
  }, [searchParams]);
  useEffect(() => {
    if (
      plan_Id[queryParams.plan_id] === queryParams.plan_id &&
      couponCode[queryParams.deals] === queryParams.deals &&
      plan_Id[currentPlan] &&
      planCards?.length > 0
    ) {
      setPlanCardList([planCards[0]]);
      setisDisable(true);
    } else {
      setPlanCardList([]);
      setisDisable(false);
    }
  }, [currentPlan, planCards]);
  const style = {
    justifyContent: "flex-start",
    gap: "1rem",
  };

  return (
    <div id="__content_payment" className="co-grid-left padding-left">
      <div className="co-form-wrapper margin-negative w-form">
        {!isDisable && (
          <div className="plansss" style={planCards.length === 2 ? style : {}}>
            {planCards.length
              ? planCards.map((plans, ind) => {
                  const {
                    actual_price,
                    offer_price,
                    total_offer_price,
                    total_actual_price,
                    id,
                    planType,
                  } = plans;
                  return (
                    <MiniCard
                      key={id}
                      className="plan complete-plan"
                      htmlFor={`minCard${ind}`}
                      onClick={() => {
                        dispatch(setCurrentPlan(id));
                      }}
                    >
                      <input
                        type="radio"
                        id={`minCard${ind}`}
                        name="plan"
                        checked={id === currentPlan}
                      />
                      <div className="plan-content">
                        <div className="plan-details">
                          <span>
                            {planType !== "Yearly" ? planType : "Annual"}
                          </span>
                          <p>
                            <span className="blk">
                              $
                              {trialStatus
                                ? planType === "Monthly"
                                  ? offer_price
                                  : offer_price
                                : offer_price}
                            </span>
                            {trialStatus && planType === "Monthly" ? (
                              <>
                                /1 <sup>st</sup>&nbsp;month
                              </>
                            ) : (
                              "/m"
                            )}
                          </p>
                          {!(offer_price === actual_price) && (
                            <p className="grn">
                              $
                              {trialStatus && planType === "Monthly"
                                ? total_actual_price - offer_price
                                : trialStatus && planType !== "Monthly"
                                ? total_offer_price
                                : total_actual_price - total_offer_price}{" "}
                              Saved
                            </p>
                          )}

                          <p>${total_offer_price} Billed Today</p>
                          {offer_price === actual_price && <p>&nbsp;</p>}
                        </div>
                      </div>
                    </MiniCard>
                  );
                })
              : Array.from({ length: 3 }, (_, i) => {
                  return (
                    <Loader variant="plans" key={i}>
                      <Load />
                      <Load />
                      <Load />
                      <Load />
                    </Loader>
                  );
                })}
          </div>
        )}
        {isDisable && (
          <div className="plansss" style={planCards.length === 2 ? style : {}}>
            {planCardList.length
              ? planCardList.map((plans, ind) => {
                  const {
                    actual_price,
                    offer_price,
                    total_offer_price,
                    total_actual_price,
                    id,
                    planType,
                  } = plans;
                  return (
                    <MiniCard
                      key={id}
                      className="plan complete-plan"
                      htmlFor={`minCard${ind}`}
                      onClick={() => {
                        dispatch(setCurrentPlan(id));
                      }}
                    >
                      <input
                        type="radio"
                        id={`minCard${ind}`}
                        name="plan"
                        checked={id === currentPlan}
                      />
                      <div className="plan-content">
                        <div className="plan-details">
                          <span>
                            {planType !== "Yearly" ? planType : "Annual"}
                          </span>
                          <p>
                            <span className="blk">${offer_price}</span> /m
                          </p>
                          {!(offer_price === actual_price) && (
                            <p className="grn">
                              ${total_actual_price - total_offer_price} Saved
                            </p>
                          )}

                          {!isDisable && (
                            <p>
                              ${total_offer_price ? "0" : total_offer_price}{" "}
                              Billed Today
                            </p>
                          )}
                          {offer_price === actual_price && <p>&nbsp;</p>}
                        </div>
                      </div>
                    </MiniCard>
                  );
                })
              : Array.from({ length: 3 }, (_, i) => {
                  return (
                    <Loader variant="plans" key={i}>
                      <Load />
                      <Load />
                      <Load />
                      <Load />
                    </Loader>
                  );
                })}
          </div>
        )}
        {/* <ConfirmPlan /> */}
        <Payment
          uId={uId}
          planId={planId}
          promoData={promoData}
          isLoading={isLoading}
          isPlanAvailable={isPlanAvailable}
          allPlans={allPlans}
          totalAmmount={totalAmmount}
        />
      </div>
    </div>
  );
};

export default ServicesPageIndex;

const MiniCard = styled.label`
  font-family: Inter, sans-serif;
  .plan-details {
    & > span {
      color: rgb(51, 51, 51);
      font-size: 18px;
      font-weight: 700;
      line-height: 2;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.9;
    }
    .blk {
      font-weight: 600;
    }
    .grn {
      /* font-weight: 000; */
    }
  }
`;
